import Heading from '@/atoms/Heading';
import Icon from '@/atoms/Icon';
import Text from '@/atoms/Text';
import Slider from '@/molecules/Slider';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { SwiperSlide } from 'swiper/react';

const WeekCarePlanOverview = () => {
    const imageList = [
        {
            id: 1,
            icon: '/images/icons/icon_personalized_meal.svg',
            mainImage: '/images/carePlan_img_1.jpg',
            tabMainImage: '/images/carePlan_img_tab_1.jpg',
            mobMainImage: '/images/carePlan_img_mob_1.jpg',
            title: 'Personalised daily meal plans',
        },
        {
            id: 2,
            icon: '/images/icons/icon_home_UV_therapy.svg',
            mainImage: '/images/carePlan_img_2.jpg',
            tabMainImage: '/images/carePlan_img_tab_2.jpg',
            mobMainImage: '/images/carePlan_img_mob_2.jpg',
            title: 'At-home UV therapy',
        },
        {
            id: 3,
            icon: '/images/icons/icon_photo_symptom_tracking.svg',
            mainImage: '/images/carePlan_img_3.jpg',
            tabMainImage: '/images/carePlan_img_tab_3.jpg',
            mobMainImage: '/images/carePlan_img_mob_3.jpg',
            title: 'Photo-based symptom tracking',
        },
        {
            id: 4,
            icon: '/images/icons/icon_regular_coach_connect.svg',
            mainImage: '/images/carePlan_img_4.jpg',
            tabMainImage: '/images/carePlan_img_tab_4.jpg',
            mobMainImage: '/images/carePlan_img_mob_4.jpg',
            title: 'Regular coach connect',
        },
        {
            id: 5,
            icon: '/images/icons/icon_wellness.svg',
            mainImage: '/images/carePlan_img_5.jpg',
            tabMainImage: '/images/carePlan_img_tab_5.jpg',
            mobMainImage: '/images/carePlan_img_mob_5.jpg',
            title: 'Curated wellness exercises',
        },
        {
            id: 6,
            icon: '/images/icons/icon_diet.svg',
            mainImage: '/images/carePlan_img_6.jpg',
            tabMainImage: '/images/carePlan_img_tab_6.jpg',
            mobMainImage: '/images/carePlan_img_mob_6.jpg',
            title: 'Healthy diet challenges',
        },
        {
            id: 7,
            icon: '/images/icons/icon_tips.svg',
            mainImage: '/images/carePlan_img_7.jpg',
            tabMainImage: '/images/carePlan_img_tab_7.jpg',
            mobMainImage: '/images/carePlan_img_mob_7.jpg',
            title: 'Helpful tips and resources',
        },
    ];

    const [selectedImage, setSelectedImage] = useState(0);
    const scrollbarRef = useRef(null);
    const scrollToPosition = (index) => {
        if (scrollbarRef.current) {
            const scrollPosition = index * 101; // Adjust this value based on your scrollbar item width
            scrollbarRef.current.scrollLeft = scrollPosition;
        }
    };

    const handleItemClick = (index) => {
        setSelectedImage(index);
        scrollToPosition(index);
    };

    const myLoader = ({ src }) => src;

    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedImage((prevSelectedImage) => {
                const newIndex =
                    prevSelectedImage === imageList.length - 1
                        ? 0
                        : prevSelectedImage + 1;
                scrollToPosition(newIndex);
                return newIndex;
            });
        }, 1000000);

        return () => clearInterval(interval);
    }, [imageList.length]);

    return (
        <>
            <div className="hidden md:block">
                <div className="relative w-full overflow-hidden">
                    <div
                        className="flex transition-transform duration-500"
                        style={{
                            transform: `translateX(-${selectedImage * 100}%)`,
                        }}
                    >
                        {imageList.map((image) => (
                            <React.Fragment key={image.id}>
                                <div className="hidden lg:block min-w-full">
                                    <Image
                                        loader={myLoader}
                                        src={image.mainImage}
                                        width={2880}
                                        height={1280}
                                        alt={image.title}
                                        className="object-cover h-[340px] 2xl:h-[450px]"
                                    />
                                </div>
                                <div className="hidden md:block lg:hidden min-w-full h-[340px]">
                                    <Image
                                        loader={myLoader}
                                        src={image.tabMainImage}
                                        width={1152}
                                        height={340}
                                        alt={image.title}
                                    />
                                </div>
                                <div className="md:hidden min-w-full">
                                    <Image
                                        loader={myLoader}
                                        src={image.mobMainImage}
                                        width={822}
                                        height={1464}
                                        alt={image.title}
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div
                    className="custom-scrollbar flex items-center bg-white overflow-auto max-w-[94%] lg:max-w-[1300px]  w-full mx-auto  xl:overflow-hidden mt-6 justify-between list-none pb-4"
                    ref={scrollbarRef}
                >
                    {imageList.map((list, index) => (
                        <li
                            key={list.id}
                            onClick={() => handleItemClick(index)}
                            className={`flex gap-2 justify-center items-center pr-4 mr-4 last:pr-0 last:mr-0 cursor-pointer border-r border-grey-shade4 last:border-r-0 md:min-w-[190px] md:w-fit xl:min-w-min ${selectedImage === index ? '' : 'bg-white'}`}
                        >
                            <Icon
                                src={list.icon}
                                width={43}
                                height={40}
                                title={list.title}
                            />
                            <Text
                                fontSize="text-body1"
                                textColor={
                                    selectedImage === index
                                        ? 'text-grey-shade1'
                                        : 'text-grey-shade3'
                                }
                                fontWeight="font-medium"
                            >
                                {list.title}
                            </Text>
                        </li>
                    ))}
                </div>
            </div>
            <div className="block md:hidden">
                <Slider
                    slidesPerView={1}
                    style={{ paddingBottom: '44px' }}
                    loop={true}
                    showPagination={false}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                >
                    {imageList.map((list, index) => (
                        <SwiperSlide key={index}>
                            <Image
                                loader={myLoader}
                                src={list.mobMainImage}
                                width={822}
                                height={1464}
                                alt={list.title}
                                className="object-cover h-[260px]"
                            />
                            <div className="flex gap-2 w-fit items-center mt-6 mx-auto">
                                <Image
                                    loader={myLoader}
                                    src={list.icon}
                                    width={43}
                                    height={40}
                                    alt="icon"
                                />

                                <Heading
                                    type="h4"
                                    fontSize="text-xl/[24px]"
                                    fontWeight="font-medium"
                                    fontColor="text-grey-shade1"
                                    fontFamily="font-body"
                                >
                                    {list.title}
                                </Heading>
                            </div>
                        </SwiperSlide>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default WeekCarePlanOverview;
