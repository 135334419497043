import PropTypes from 'prop-types';
import Avatar from '@/atoms/Avatar';
import Text from '@/atoms/Text';
import React from 'react';

const Profile = ({
    avatarSrc,
    title,
    description,
    availableOn,
    className,
    onClick,
    showAvailableOn,
    titleVariant,
    titleFontWeight,
    titleColor,
    descriptionVariant,
    descriptionFontWeight,
    descriptionColor,
    ...rest
}) => {
    return (
        <div
            className={`flex items-start lg:items-center gap-3 ${className}`}
            onClick={onClick}
            {...rest}
        >
            <Avatar
                className="flex-shrink-0"
                size="large"
                shape="circle"
                imgSrc={avatarSrc}
            />
            <div>
                <Text
                    variant={titleVariant}
                    fontWeight={titleFontWeight}
                    textColor={titleColor}
                >
                    {title}
                </Text>
                <Text
                    variant={descriptionVariant}
                    fontWeight={descriptionFontWeight}
                    textColor={descriptionColor}
                >
                    {description}
                </Text>
                {showAvailableOn && (
                    <Text
                        variant="caption"
                        textColor="text-grey-shade1"
                        className="mt-2"
                    >
                        Available on call :
                        <span className="text-grey-shade3">{availableOn}</span>
                    </Text>
                )}
            </div>
        </div>
    );
};

Profile.propTypes = {
    avatarSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    availableOn: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    showAvailableOn: PropTypes.bool,
    titleVariant: PropTypes.string,
    titleFontWeight: PropTypes.string,
    titleColor: PropTypes.string,
    descriptionVariant: PropTypes.string,
    descriptionFontWeight: PropTypes.string,
    descriptionColor: PropTypes.string,
};

Profile.defaultProps = {
    availableOn: 'Once in 2 weeks',
    className: '',
    onClick: () => {},
    showAvailableOn: true,
    titleVariant: 'subHeading',
    titleFontWeight: 'font-medium',
    titleColor: 'text-grey-shade1',
    descriptionVariant: 'body2',
    descriptionFontWeight: 'font-medium',
    descriptionColor: 'text-grey-shade3',
};

export default Profile;
