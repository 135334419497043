import React from 'react';
import NextImage from 'next/image';
import Heading from '@/atoms/Heading';
import ListItem from '@/atoms/ListItem';
import Button from '@/atoms/Button';
import PropTypes from 'prop-types';
import { redirectTo } from 'utils/router';

const Banner = ({
    handleBuyNow = () => {},
    handleRequestCallback = () => {},
    handleRenew = () => {
        redirectTo('/purchase-history');
    },
    heroBannerData,
}) => {
    const myLoader = ({ src }) => src;

    const list = heroBannerData.acf.bullet_points.map((point) => {
        return {
            item: point.bullet_point,
            listIconAlt: 'check icon',
            iconSrc: '/images/icons/icck.svg',
        };
    });

    return (
        <div className="relative z-10 pt-6 md:pt-[60px] overflow-hidden -mb-5">
            <div
                className="h-[40%] md:h-[50%] lg:h-full w-screen absolute left-0 right-0 -z-10 top-0 bg-no-repeat bg-[center_bottom_100px] "
                style={{
                    backgroundImage: 'url(/images/Bumpshape.jpg)',
                    backgroundSize: 'cover',
                }}
            />
            <div className="container">
                <div className="bg-white rounded-[2.5rem] px-4 md:px-10 lg:pl-[80px] lg:pr-10 pt-16 pb-8 md:py-12 flex flex-wrap relative shadow-xs mb-5">
                    <div
                        className="cursor-pointer w-fit rounded-bl-3xl rounded-tr-3xl md:rounded-tl-[60px] md:rounded-bl-[60px] text-white font-medium font-body text-sm flex items-center gap-x-2 bg-blue py-2 px-6 absolute right-0 top-0 md:top-10 z-10"
                        onClick={handleRenew}
                    >
                        <NextImage
                            src="/images/icons/refresh.svg"
                            width={24}
                            height={24}
                        />
                        Renew plan
                    </div>
                    <div
                        className="w-full md:w-[56%]"
                        data-aos="fade-right"
                        data-aos-delay="200"
                        data-aos-once="true"
                    >
                        <div className="relative w-[228px] h-[40px] mx-auto md:mx-0 md:w-[350px] md:h-20 mb-8 md:md-10 lg:mb-[60px]">
                            <NextImage
                                src="/images/logo_footer.svg"
                                alt="logo"
                                layout="fill"
                            />
                        </div>
                        <div className="flex items-center md:hidden">
                            <Heading
                                type="h1"
                                fontColor="text-black"
                                fontFamily="font-sectionHeading"
                                className="w-[60%]"
                            >
                                {heroBannerData.title.rendered}
                            </Heading>
                            <NextImage
                                loader={myLoader}
                                src={heroBannerData.acf.image}
                                alt="banner image"
                                width={158}
                                height={180}
                                className="w-[40%] block md:hidden pl-2"
                            />
                        </div>
                        <Heading
                            type="h1"
                            fontColor="text-black"
                            fontFamily="font-sectionHeading"
                            className="hidden md:block"
                        >
                            {heroBannerData.title.rendered}
                        </Heading>
                        <ListItem
                            listFontSize="text-sm md:text-xl"
                            listColor="text-grey-shade2"
                            listClass="font-medium  py-8 md:pb-[60px]"
                            iconWidth={24}
                            iconHeight={24}
                            lists={list}
                            itemClass="mb-1"
                        />
                        <div className="flex gap-3 md:gap-5 items-start">
                            <Button
                                id="buyNow"
                                label="Buy Now"
                                size="large2"
                                onClick={handleBuyNow}
                                btnClass="flex-1 sm:flex-none whitespace-pre"
                            />
                            <Button
                                id="requestACallback"
                                label="Request a Callback"
                                size="large2"
                                style="ghost"
                                onClick={handleRequestCallback}
                                btnClass="flex-1 sm:flex-none whitespace-pre"
                            />
                        </div>
                    </div>
                    <div
                        className="w-full md:w-[44%] relative hidden md:flex items-center pl-9 lg:pl-12"
                        data-aos="fade-left"
                        data-aos-delay="400"
                        data-aos-once="true"
                    >
                        <NextImage
                            loader={myLoader}
                            src={heroBannerData.acf.image}
                            alt="banner image"
                            width={351}
                            height={476}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Banner.propTypes = {
    handleBuyNow: PropTypes.func,
    handleRequestCallback: PropTypes.func,
    handleRenew: PropTypes.func,
    handleWhatsappChat: PropTypes.func,
    heroBannerData: PropTypes.object.isRequired,
};
export default Banner;
