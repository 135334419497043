import Image from 'next/image';
import Text from '@/atoms/Text';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

const UVDeviceFeatureCard = ({ uvDeviceFeatureCardData }) => {
    const myLoader = ({ src }) => src;
    return (
        <div className="bg-grey-shade6 pt-10 pb-6 md:py-[60px] lg:py-10 px-6 lg:px-10 rounded-[20px] min-h-[332px]">
            <div className="relative justify-between hidden md:flex w-full">
                <div
                    className="flex items-center justify-center lg:max-w-[327px] max-w-[287px] mx-auto"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <Image
                        loader={myLoader}
                        src={uvDeviceFeatureCardData.image}
                        width={327}
                        height={320}
                        alt="device"
                    />
                </div>

                <div
                    className="hidden md:flex gap-3 absolute left-[20px] lg:left-[150px] top-0 lg:top-[20px] lg:max-w-max max-w-[226px]"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-once="true"
                >
                    <div className="max-w-[60px] lg:max-w-[80px]">
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.bullet[0].bullet_image}
                            width={80}
                            height={80}
                            alt="device"
                        />
                    </div>
                    <div>
                        <Image
                            src="/images/device_line_1.svg"
                            width={244}
                            height={8}
                            alt="line"
                        />
                        <Text
                            variant="body1"
                            textColor="text-grey-shade3"
                            className="max-w-[176px] mt-2"
                        >
                            <span className="text-base text-grey-shade1 font-medium block">
                                {uvDeviceFeatureCardData.bullet[0].title}
                            </span>
                            {uvDeviceFeatureCardData.bullet[0].subtitle}
                        </Text>
                    </div>
                </div>
                <div
                    className="hidden md:flex gap-3 absolute left-0 lg:left-[40px] bottom-[60px] lg:bottom-[40px] lg:max-w-max max-w-[215px]"
                    data-aos="fade-right"
                    data-aos-delay="700"
                    data-aos-once="true"
                >
                    <div className="max-w-[60px] lg:max-w-[80px]">
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.bullet[1].bullet_image}
                            width={80}
                            height={80}
                            alt="device"
                        />
                    </div>
                    <div>
                        <Image
                            src="/images/device_line_2.svg"
                            width={244}
                            height={8}
                            alt="line"
                        />
                        <Text
                            variant="body1"
                            textColor="text-grey-shade3"
                            className="max-w-[188px] mt-2"
                        >
                            <span className="text-base text-grey-shade1 font-medium block">
                                {uvDeviceFeatureCardData.bullet[1].title}
                            </span>
                            {uvDeviceFeatureCardData.bullet[1].subtitle}
                        </Text>
                    </div>
                </div>
                <div
                    className="hidden md:flex items-center gap-3 absolute lg:max-w-max max-w-[226px] right-0 lg:right-[30px] -bottom-[24px] lg:bottom-[56px]"
                    data-aos="fade-left"
                    data-aos-delay="1000"
                    data-aos-once="true"
                >
                    <div>
                        <Image
                            src="/images/device_line_3.svg"
                            width={244}
                            height={8}
                            alt="line"
                        />
                        <Text
                            variant="body1"
                            textColor="text-grey-shade3"
                            className="max-w-[154px] text-right mt-2 ml-auto"
                        >
                            <span className="text-base text-grey-shade1 font-medium block">
                                {uvDeviceFeatureCardData.bullet[2].title}
                            </span>
                            {uvDeviceFeatureCardData.bullet[2].subtitle}
                        </Text>
                    </div>
                    <div className="max-w-[60px] lg:max-w-[80px]">
                        <Image
                            loader={myLoader}
                            src={uvDeviceFeatureCardData.bullet[2].bullet_image}
                            width={80}
                            height={80}
                            alt="device"
                        />
                    </div>
                </div>
            </div>
            <div className="md:hidden">
                <Image
                    loader={myLoader}
                    src={uvDeviceFeatureCardData.image}
                    width={327}
                    height={320}
                    alt="device"
                    className="h-[180px] object-contain mb-6 mx-auto"
                />

                <Slider
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    showPagination={false}
                    style={{
                        '--swiper-pagination-bullet-size': '0',
                    }}
                >
                    <SwiperSlide>
                        <div className="flex items-center gap-3 h-[64px]">
                            <div className="max-w-[60px]">
                                <Image
                                    loader={myLoader}
                                    src={
                                        uvDeviceFeatureCardData.bullet[0]
                                            .bullet_image
                                    }
                                    width={80}
                                    height={80}
                                    alt="device"
                                />
                            </div>
                            <div>
                                <Text
                                    variant="body1"
                                    textColor="text-grey-shade3"
                                    className="mt-2"
                                >
                                    <span className="text-base text-grey-shade1 font-medium block">
                                        {
                                            uvDeviceFeatureCardData.bullet[0]
                                                .title
                                        }
                                    </span>
                                    {uvDeviceFeatureCardData.bullet[0].subtitle}
                                </Text>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="flex items-center gap-3 h-[64px]">
                            <div className="max-w-[60px]">
                                <Image
                                    loader={myLoader}
                                    src={
                                        uvDeviceFeatureCardData.bullet[1]
                                            .bullet_image
                                    }
                                    width={80}
                                    height={80}
                                    alt="device"
                                />
                            </div>
                            <div>
                                <Text
                                    variant="body1"
                                    textColor="text-grey-shade3"
                                    className="mt-2"
                                >
                                    <span className="text-base text-grey-shade1 font-medium block">
                                        {
                                            uvDeviceFeatureCardData.bullet[1]
                                                .title
                                        }
                                    </span>
                                    {uvDeviceFeatureCardData.bullet[1].subtitle}
                                </Text>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="flex items-center gap-3 h-[64px]">
                            <div className="max-w-[48px]">
                                <Image
                                    loader={myLoader}
                                    src={
                                        uvDeviceFeatureCardData.bullet[2]
                                            .bullet_image
                                    }
                                    width={80}
                                    height={80}
                                    alt="device"
                                />
                            </div>
                            <div>
                                <Text
                                    variant="body1"
                                    textColor="text-grey-shade3"
                                    className="mt-2"
                                >
                                    <span className="text-base text-grey-shade1 font-medium block">
                                        {
                                            uvDeviceFeatureCardData.bullet[2]
                                                .title
                                        }
                                    </span>
                                    {uvDeviceFeatureCardData.bullet[2].subtitle}
                                </Text>
                            </div>
                        </div>
                    </SwiperSlide>
                </Slider>
            </div>
        </div>
    );
};

UVDeviceFeatureCard.defaultProps = {};

UVDeviceFeatureCard.propTypes = {
    uvDeviceFeatureCardData: PropTypes.shape({
        image: PropTypes.string,
        bullet: PropTypes.arrayOf(
            PropTypes.shape({
                bullet_image: PropTypes.string,
                title: PropTypes.string,
                subtitle: PropTypes.string,
            })
        ),
    }),
};

export default UVDeviceFeatureCard;
