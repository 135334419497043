import Heading from '@/atoms/Heading';
import Profile from '@/molecules/Profile';
import NextImage from 'next/image';
import PropTypes from 'prop-types';

const Help = ({ profileList, className, slide2Image, slide2Heading }) => {
    return (
        <div
            className={`container px-0 flex flex-col md:flex-row gap-5 justify-center items-center ${className || ''} flex items-center`}
        >
            <div className="relative">
                <NextImage
                    loader={({ src }) => src}
                    src={slide2Image}
                    alt="Whole Team Coaches image"
                    width={232}
                    height={386}
                    className="hidden md:block md:w-[320px] lg:w-[232px]"
                />
            </div>

            <div className="md:w-[55%] lg:w-fit">
                <Heading
                    type="h3"
                    fontWeight="font-medium"
                    fontColor="text-black"
                    className="mb-6"
                    fontSize="text-[23px]/[28px] md:text-h3"
                    fontFamily="font-body md:font-heading"
                >
                    {slide2Heading}
                </Heading>
                {profileList?.map((item) => (
                    <Profile
                        key={item.id}
                        availableOn={item.availableOn}
                        avatarSrc={item.avatarSrc}
                        description={item.description}
                        title={item.title}
                        className="mb-4 md:mb-3 last:mb-0"
                    />
                ))}
            </div>
        </div>
    );
};

Help.propTypes = {
    className: PropTypes.string,
    profileList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            availableOn: PropTypes.string.isRequired,
            avatarSrc: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    slide2Image: PropTypes.string.isRequired,
    slide2Heading: PropTypes.string.isRequired,
};
export default Help;
