import { useReducer } from 'react';
import PropTypes from 'prop-types';
import Icon from '@/atoms/Icon';
import Image from 'next/image';

const reducer = (state, action) => {
    switch (action.type) {
        case 'change':
            return {
                rangeValue: action.payload,
            };
        case 'move':
            return {
                rangeValue: Math.round(action.payload),
            };
        default:
            return state;
    }
};

const BeforeAfter = ({
    beforeImg,
    afterImg,
    imgWidth,
    imgHeight,
    onChange,
    onPointerMove,
    onPointerEnter,
    onPointerLeave,
    pointerMove = false,
    className = 'before-after-slider',
    beforeClassName = 'before',
    afterClassName = 'after',
    buttonClassName = 'resize-button',
    beforeStyle,
    afterStyle,
    children,
}) => {
    const myLoader = ({ src }) => src;
    const [{ rangeValue }, dispatch] = useReducer(reducer, {
        rangeValue: 50,
    });

    const handleChange = (event) => {
        dispatch({ type: 'change', payload: Number(event.target.value) });
        if (onChange) onChange(event);
    };

    const handlePointerMove = (event) => {
        const { clientX, currentTarget } = event;
        const { left, width } = currentTarget.getBoundingClientRect();
        const positionX = clientX - left;

        if (positionX >= 0)
            dispatch({ type: 'move', payload: (positionX / width) * 100 });

        if (onPointerMove) onPointerMove(event);
    };

    const handlePointerEnter = (event) => {
        if (onPointerEnter) onPointerEnter(event);
    };

    const handlePointerLeave = (event) => {
        if (onPointerLeave) onPointerLeave(event);
    };

    return (
        <div
            className={`relative overflow-hidden cursor-ew-resize select-none w-full h-full md:h-[565px] lg:h-full  ${className}`}
            onPointerMove={pointerMove ? handlePointerMove : undefined}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
        >
            <div
                className={`absolute overflow-hidden h-full top-0 left-0 border-r border-white ${beforeClassName}`}
                style={{
                    width: `${rangeValue}%`,
                    ...beforeStyle,
                }}
            >
                <Image
                    loader={myLoader}
                    src={beforeImg}
                    width={imgWidth}
                    height={imgHeight}
                    alt="Before"
                    className="max-w-max h-full md:h-[565px] lg:h-full "
                />
            </div>

            <div className={afterClassName} style={afterStyle}>
                <Image
                    loader={myLoader}
                    src={afterImg}
                    width={imgWidth}
                    height={imgHeight}
                    alt="After"
                    className="md:max-w-max lg:max-w-[980px] h-full md:h-[565px] lg:h-fit"
                />
            </div>

            {!pointerMove && (
                <>
                    <input
                        type="range"
                        min={0}
                        max={100}
                        value={rangeValue}
                        name="slider"
                        onChange={handleChange}
                        className="appearance-none bg-transparent w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-ew-resize"
                    />
                    <div
                        className={`pointer-events-none absolute top-1/2 -ml-5 transform -translate-y-1/2 rounded-full w-30 h-30 flex justify-center items-center ${buttonClassName}`}
                        style={{
                            left: `${rangeValue}%`,
                        }}
                    >
                        <Icon
                            src="images/icons/icon_drag.svg"
                            width={40}
                            height={40}
                            alt="drag"
                        />
                    </div>
                </>
            )}
            {children}
        </div>
    );
};

BeforeAfter.propTypes = {
    beforeImg: PropTypes.string.isRequired,
    afterImg: PropTypes.string.isRequired,
    imgWidth: PropTypes.number.isRequired,
    imgHeight: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    onPointerMove: PropTypes.func,
    onPointerEnter: PropTypes.func,
    onPointerLeave: PropTypes.func,
    pointerMove: PropTypes.bool,
    className: PropTypes.string,
    beforeClassName: PropTypes.string,
    afterClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    beforeStyle: PropTypes.object,
    afterStyle: PropTypes.object,
    children: PropTypes.node,
};

export default BeforeAfter;
