import React from 'react';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import Text from '@/atoms/Text';
const Overview = ({
    number,
    imageSrc,
    imageAlt,
    heading,
    bgColor = 'bg-[#EAFEF0]',
    textColor = 'text-[#AAFAC2]',
}) => {
    return (
        <div className="relative max-w-[285px] w-full mx-auto mt-10 md:mt-0">
            <div
                className={`w-[60px] lg:w-[70px] h-[60px] lg:h-[70px] ${bgColor} overflow-hidden flex justify-center items-center rounded-full font-sectionHeading ${textColor} text-4xl/[38px] 
                lg:text-5xl absolute -top-10 md:-left-9 lg:-left-0 pt-3`}
            >
                {number}
            </div>
            <div
                className={`w-[200px] lg:w-[220px] h-[200px] lg:h-[220px] mx-auto rounded-full ${bgColor} flex justify-center overflow-hidden pt-10`}
            >
                <NextImage
                    loader={({ src }) => src}
                    src={imageSrc}
                    alt={imageAlt}
                    width={154}
                    height={180}
                />
            </div>
            <Text
                variant=""
                textColor="text-grey-shade1"
                fontWeight="font-medium"
                className="text-body4 lg:text-base mt-3 text-center"
            >
                {heading}
            </Text>
        </div>
    );
};

Overview.propTypes = {
    number: PropTypes.number,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    heading: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
};
export default Overview;
