import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';
import GuidedRecoveryBanner from './GuidedRecoveryBanner';
import Help from './Help';
import InfoGallery from './InfoGallery';
import SectionHeading from '@/atoms/SectionHeading';
import Button from '@/atoms/Button';
import GuidedRecoveryBannerMobile from './GuidedRecoveryBannerMobile';
import { redirectTo } from 'utils/router';
import { CARE_PLAN_NAME } from 'constants/plan-constants';

const PsoriasisCareLevelSlider = ({ handleBuyNow, psoriasisCareLevelData }) => {
    const guidedRecoveryBannerDatalist = [];
    const profileDatalist = [];
    let slide1Image;
    let slide2Heading;
    let slide2Image;
    let slide3Heading;
    const infoPersonalDatalist = [];
    const infoScientificallyDatalist = [];
    let slide4Heading;

    const infoMealDatalist = [];
    let slide5Heading;
    let cardTitle;

    psoriasisCareLevelData.forEach((list) => {
        if (list.acf.type_of_slide === 'Type 1') {
            slide1Image = list.acf.slide_1_details.image.url;
            cardTitle = list.acf.slide_1_details.heading;
            let guidedRecoveryBannerData =
                list.acf.slide_1_details.text_details.map((entity, idx) => {
                    return {
                        id: idx + 1,
                        heading: entity.title,
                        subHeading: entity.subtitle,
                        imgSrc: entity.mobile_version_image.url,
                    };
                });
            guidedRecoveryBannerDatalist.push(guidedRecoveryBannerData);
        } else if (list.acf.type_of_slide === 'Type 2') {
            slide2Heading = list.acf.slide_2_details.heading;
            slide2Image = list.acf.slide_2_details.image.url;
            let profileList = list.acf.slide_2_details.profile_details.map(
                (entity, idx) => {
                    return {
                        id: idx + 1,
                        availableOn: entity.duration,
                        avatarSrc: entity.profile_image.url,
                        title: entity.profile_name,
                        description: entity.description,
                    };
                }
            );
            profileDatalist.push(profileList);
        } else if (
            list.acf.type_of_slide === 'Type 3' &&
            list.acf.slide_3_details.color_theme === 'green'
        ) {
            slide3Heading = list.acf.slide_3_details.heading;
            let infoPersonalList = list.acf.slide_3_details.card_details.map(
                (entity, idx) => {
                    return {
                        id: idx + 1,
                        number: idx + 1,
                        imageSrc: entity.image.url,
                        heading: entity.subtext,
                        imageAlt: 'personalised meal',
                    };
                }
            );
            infoPersonalDatalist.push(infoPersonalList);
        } else if (
            list.acf.type_of_slide === 'Type 3' &&
            list.acf.slide_3_details.color_theme === 'yellow'
        ) {
            slide4Heading = list.acf.slide_3_details.heading;
            let infoScientificallyList =
                list.acf.slide_3_details.card_details.map((entity, idx) => {
                    return {
                        id: idx + 1,
                        number: idx + 1,
                        imageSrc: entity.image.url,
                        heading: entity.subtext,
                        imageAlt: 'personalised meal',
                    };
                });
            infoScientificallyDatalist.push(infoScientificallyList);
        } else if (
            list.acf.type_of_slide === 'Type 3' &&
            list.acf.slide_3_details.color_theme === 'purple'
        ) {
            slide5Heading = list.acf.slide_3_details.heading;
            let infoMealList = list.acf.slide_3_details.card_details.map(
                (entity, idx) => {
                    return {
                        id: idx + 1,
                        number: idx + 1,
                        imageSrc: entity.image.url,
                        heading: entity.subtext,
                        imageAlt: 'personalised meal',
                    };
                }
            );
            infoMealDatalist.push(infoMealList);
        }
    });

    const [isSticky, setIsSticky] = useState(false);
    const sectionRef = useRef(null);
    const [resizeTimeout, setResizeTimeout] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            const isMobileView = window.innerWidth < 768;
            if (sectionRef.current && isMobileView) {
                const { top, bottom } =
                    sectionRef.current.getBoundingClientRect();
                if (top <= 80 && bottom > 400) {
                    setIsSticky(true);
                } else {
                    setIsSticky(false);
                }
            } else {
                setIsSticky(false);
            }
        };

        const handleResize = () => {
            clearTimeout(resizeTimeout);
            setResizeTimeout(setTimeout(handleScroll, 100));
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize); // Handle resize to apply/remove sticky based on window width

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [resizeTimeout]);

    return (
        <>
            <div className="container takes-section" ref={sectionRef}>
                <SectionHeading
                    className={`mb-6 mx-auto max-w-[570px] ${isSticky ? 'fixed top-[70px] left-0 bg-white z-50 p-4' : ''}`}
                    align="center"
                    fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                >
                    {cardTitle}
                </SectionHeading>
                <div className="hidden md:block">
                    <Slider
                        slidesPerView={1}
                        style={{ paddingBottom: '44px' }}
                        loop={false}
                        showPagination={false}
                    >
                        {guidedRecoveryBannerDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <GuidedRecoveryBanner
                                    className="hidden md:block"
                                    guidedRecoveryBannerData={data}
                                    slide1Image={slide1Image}
                                />
                            </SwiperSlide>
                        ))}
                        {profileDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <Help
                                    profileList={data}
                                    slide2Heading={slide2Heading}
                                    slide2Image={slide2Image}
                                />
                            </SwiperSlide>
                        ))}
                        {infoPersonalDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <InfoGallery
                                    bgColor="bg-green-shade3"
                                    heading={slide3Heading}
                                    list={data}
                                    textColor="text-[#AAFAC2]"
                                />
                            </SwiperSlide>
                        ))}
                        {infoScientificallyDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <InfoGallery
                                    bgColor="bg-yellow-shade3"
                                    heading={slide4Heading}
                                    list={data}
                                    textColor="text-[#F8DCA3]"
                                />
                            </SwiperSlide>
                        ))}
                        {infoMealDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <InfoGallery
                                    bgColor="bg-secondary-shade3"
                                    heading={slide5Heading}
                                    list={data}
                                    textColor="text-secondary-shade1"
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
                <div className="flex flex-col gap-8 md:hidden">
                    {guidedRecoveryBannerDatalist.map((data, idx) => (
                        <div key={idx}>
                            <GuidedRecoveryBannerMobile
                                guidedRecoveryList={data}
                            />
                        </div>
                    ))}
                    {profileDatalist.map((data, idx) => (
                        <div key={idx}>
                            <Help
                                profileList={data}
                                slide2Heading={slide2Heading}
                                slide2Image={slide2Image}
                            />
                        </div>
                    ))}
                    {infoPersonalDatalist.map((data, idx) => (
                        <div key={idx}>
                            <InfoGallery
                                bgColor="bg-green-shade3"
                                heading={slide3Heading}
                                list={data}
                                textColor="text-[#AAFAC2]"
                            />
                        </div>
                    ))}
                    {infoScientificallyDatalist.map((data, idx) => (
                        <div key={idx}>
                            <InfoGallery
                                bgColor="bg-yellow-shade3"
                                heading={slide4Heading}
                                list={data}
                                textColor="text-[#F8DCA3]"
                            />
                        </div>
                    ))}
                    {infoMealDatalist.map((data, idx) => (
                        <div key={idx}>
                            <InfoGallery
                                bgColor="bg-[#F1E8FD]"
                                heading={slide5Heading}
                                list={data}
                                textColor="text-[#C8A3F8]"
                            />
                        </div>
                    ))}
                </div>
                <div className="flex flex-col xsm:flex-row  justify-center gap-5 mt-6">
                    <Button
                        label="Buy Now"
                        style="primary"
                        size="large"
                        onClick={handleBuyNow}
                    />
                    <Button
                        label="Know more"
                        style="ghost"
                        size="large"
                        onClick={() => {
                            redirectTo(`/plans/${CARE_PLAN_NAME}`);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

PsoriasisCareLevelSlider.propTypes = {
    handleBuyNow: PropTypes.func,
    psoriasisCareLevelData: PropTypes.array,
};

export default PsoriasisCareLevelSlider;
