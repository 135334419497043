import PropTypes from 'prop-types';
import UVDeviceFeatureCard from './UVDeviceFeatureCard';
import UVDeviceHightLightCard from './UVDeviceHightLightCard';
import UVDeviceVideoCard from './UVDeviceVideoCard';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';
import Button from '@/atoms/Button';
import { redirectTo } from 'utils/router';
import { DEVICE_ONLY_PLAN_NAME } from 'constants/plan-constants';
import SectionHeading from '@/atoms/SectionHeading';
import { useState } from 'react';

const UVDeviceSection = ({ handleBuyUvDevicePlanNow, uvDeviceSectionData }) => {
    const uvDeviceFeatureCardDatalist = [];
    const uvDeviceHighlightCardDatalist = [];
    const uvDeviceVideoCardDatalist = [];
    let videoLink;
    let cardTitle;
    uvDeviceSectionData.forEach((list) => {
        if (list.acf.type_of_slide === 'type 1') {
            cardTitle = list.acf.heading;
            let uvDeviceFeatureCardData = {
                image: list.acf.image_1.url,
                bullet: list.acf.bullets,
            };
            uvDeviceFeatureCardDatalist.push(uvDeviceFeatureCardData);
        } else if (list.acf.type_of_slide === 'type 2') {
            let uvDeviceHighlightCardData = {
                image: list.acf.image_2.url,
                bullets: list.acf.second_slide_bullets,
            };
            uvDeviceHighlightCardDatalist.push(uvDeviceHighlightCardData);
        } else if (list.acf.type_of_slide === 'type 3') {
            videoLink = list.acf.slide_3_details.video.url;
            let uvDeviceVideoCardData = {
                title: list.acf.slide_3_details.title,
                subtitle: list.acf.slide_3_details.subtitle,
                videoThumbnail: list.acf.slide_3_details.video_thumbnail.url,
            };
            uvDeviceVideoCardDatalist.push(uvDeviceVideoCardData);
        }
    });

    const ArraySlider = ({
        ComponentToRender,
        getPropData,
        arrayData,
        onChangeKey,
        handleChange,
    }) => {
        const [currentIdx, setCurrentIdx] = useState(0);
        return [arrayData[currentIdx]].map((data, idx) => (
            <ComponentToRender
                key={idx}
                {...getPropData(data)}
                {...{
                    [onChangeKey]: (e) => {
                        if (currentIdx === arrayData.length - 1) {
                            handleChange(e);
                            setCurrentIdx(0);
                        } else {
                            setCurrentIdx((prevIdx) => {
                                const newIdx = prevIdx + 1;
                                return arrayData.length - 1 < newIdx
                                    ? 0
                                    : newIdx;
                            });
                        }
                    },
                }}
            />
        ));
    };

    return (
        <div className="container">
            <div className="">
                <SectionHeading
                    align="center"
                    fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                    className="mb-6"
                >
                    {cardTitle}
                </SectionHeading>
                <div className="hidden md:block">
                    <Slider
                        slidesPerView={1}
                        style={{ paddingBottom: '44px' }}
                        showPagination={false}
                        loop={true}
                    >
                        <SwiperSlide>
                            <ArraySlider
                                arrayData={uvDeviceFeatureCardDatalist}
                                ComponentToRender={UVDeviceFeatureCard}
                                getPropData={(data) => ({
                                    handleBuyUvDevicePlanNow:
                                        handleBuyUvDevicePlanNow,
                                    uvDeviceFeatureCardData: data,
                                })}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <ArraySlider
                                arrayData={uvDeviceHighlightCardDatalist}
                                ComponentToRender={UVDeviceHightLightCard}
                                getPropData={(data) => ({
                                    handleBuyUvDevicePlanNow:
                                        handleBuyUvDevicePlanNow,
                                    uvDeviceHighlightCardData: data,
                                })}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <ArraySlider
                                arrayData={uvDeviceVideoCardDatalist}
                                ComponentToRender={UVDeviceVideoCard}
                                getPropData={(data) => ({
                                    handleBuyUvDevicePlanNow:
                                        handleBuyUvDevicePlanNow,
                                    uvDeviceVideoCardData: data,
                                    videoLink: videoLink,
                                })}
                            />
                        </SwiperSlide>
                    </Slider>
                </div>
                <div className="block md:hidden">
                    <Slider
                        slidesPerView={1}
                        style={{ paddingBottom: '44px' }}
                        showPagination={false}
                        loop={false}
                    >
                        {uvDeviceFeatureCardDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <UVDeviceFeatureCard
                                    uvDeviceFeatureCardData={data}
                                />
                            </SwiperSlide>
                        ))}
                        {uvDeviceHighlightCardDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <UVDeviceHightLightCard
                                    showFirstList={true}
                                    showSecondList={false}
                                    uvDeviceHighlightCardData={data}
                                />
                            </SwiperSlide>
                        ))}
                        {uvDeviceHighlightCardDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <UVDeviceHightLightCard
                                    showFirstList={false}
                                    showSecondList={true}
                                    uvDeviceHighlightCardData={data}
                                />
                            </SwiperSlide>
                        ))}
                        {uvDeviceVideoCardDatalist.map((data, idx) => (
                            <SwiperSlide key={idx}>
                                <UVDeviceVideoCard
                                    uvDeviceVideoCardData={data}
                                    videoLink={videoLink}
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
                <div className="flex flex-col xsm:flex-row justify-center gap-5 mt-6">
                    <Button
                        label="Buy Now"
                        style="primary"
                        size="large"
                        onClick={handleBuyUvDevicePlanNow}
                    />
                    <Button
                        label="Know more"
                        style="ghost"
                        size="large"
                        onClick={() => {
                            redirectTo(`/plans/${DEVICE_ONLY_PLAN_NAME}`);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

UVDeviceSection.defaultProps = {
    handleBuyUvDevicePlanNow: () => {},
    uvDeviceSectionData: [],
};

UVDeviceSection.propTypes = {
    handleBuyUvDevicePlanNow: PropTypes.func,
    uvDeviceSectionData: PropTypes.array,
};

export default UVDeviceSection;
