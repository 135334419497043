import PropTypes from 'prop-types';
import Image from 'next/image';
import FeatureIcon from '@/molecules/FeatureIcon';

const UVDeviceHightLightCard = ({
    handleComponentChange,
    showFirstList,
    showSecondList,
    uvDeviceHighlightCardData,
}) => {
    const featuresList = uvDeviceHighlightCardData.bullets.map((list) => {
        return {
            icon: list.bullet_icon.url,
            title: list.title,
            desc: list.subtitle,
        };
    });

    const myLoader = ({ src }) => src;

    return (
        <div
            className={`bg-secondary-shade3 flex justify-between md:gap-12 lg:gap-6 py-6 pl-4 pr-6 md:py-[34px] md:px-10 lg:py-10 lg:px-[60px] rounded-[20px] min-h-[332px]`}
            onClick={handleComponentChange}
        >
            {showFirstList && (
                <div className="w-full md:w-1/2 lg:w-1/3 flex flex-col gap-6 md:gap-10">
                    {featuresList.slice(0, 3).map((list, index) => (
                        <div
                            key={index}
                            data-aos="fade-up"
                            data-aos-delay={index * 200}
                            data-aos-once="true"
                        >
                            <FeatureIcon
                                icon={list.icon}
                                title={list.title}
                                desc={list.desc}
                            />
                        </div>
                    ))}
                </div>
            )}

            <div
                className="w-1/3 hidden lg:flex items-center justify-center"
                data-aos="zoom-out"
                data-aos-duration="2000"
                data-aos-once="true"
            >
                <Image
                    loader={myLoader}
                    src={uvDeviceHighlightCardData.image}
                    width={327}
                    height={320}
                    alt="device"
                />
            </div>

            {showSecondList && (
                <div
                    className={`w-full md:w-1/2 lg:w-1/3 flex flex-col gap-6 md:gap-10`}
                >
                    {featuresList.slice(3).map((list, index) => (
                        <div
                            key={index}
                            data-aos="fade-up"
                            data-aos-delay={index * 200}
                            data-aos-once="true"
                        >
                            <FeatureIcon
                                icon={list.icon}
                                title={list.title}
                                desc={list.desc}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
UVDeviceHightLightCard.defaultProps = {
    showFirstList: true,
    showSecondList: true,
    className: '',
};

UVDeviceHightLightCard.propTypes = {
    showFirstList: PropTypes.bool,
    showSecondList: PropTypes.bool,
    className: PropTypes.string,
    handleComponentChange: PropTypes.func,
    uvDeviceHighlightCardData: PropTypes.object,
};

export default UVDeviceHightLightCard;
