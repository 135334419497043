import PropTypes from 'prop-types';
import BeforeAfter from './BeforeAfter';
import TestimonialCard from './TestimonialCard';
import Text from '@/atoms/Text';

const UVTherapyCard = ({
    beforeImg,
    afterImg,
    title,
    desc,
    profileImg,
    profileName,
}) => {
    return (
        <>
            <BeforeAfter
                className="rounded-t-xl md:rounded-[20px]"
                beforeImg={beforeImg}
                afterImg={afterImg}
                imgWidth={980}
                imgHeight={513}
            ></BeforeAfter>
            <Text
                fontSize="text-caption md:text-subHeading"
                className="absolute bg-grey-shade3 bg-opacity-60 px-2 py-1.5 md:px-5 md:py-3 top-2 left-2 md:top-8 md:left-8 rounded-xl"
                key={2}
                textColor="text-white"
            >
                Before {title}
            </Text>

            <TestimonialCard
                key={3}
                className=" md:max-w-[218px] md:absolute bottom-8 left-8 z-20 cursor-pointer p-4 md:px-4 md:py-5"
                desc={desc}
                descMaxLength={61}
                profileImg={profileImg}
                profileName={profileName}
            />
            <Text
                fontSize="text-caption md:text-subHeading"
                className="absolute bg-grey-shade3 bg-opacity-60 px-2 py-1.5 md:px-5 md:py-3 top-2 right-2 md:top-8 md:right-8 rounded-xl"
                key={2}
                textColor="text-white"
            >
                After {title}
            </Text>
        </>
    );
};

UVTherapyCard.defaultProps = {
    beforeImg: '/images/uv_therapy_before_img.png',
    afterImg: '/images/uv_therapy_after_mob.jpg',
    title: 'UV Therapy',
    desc: '',
    profileImg: '',
    profileName: '',
};

UVTherapyCard.propTypes = {
    beforeImg: PropTypes.string,
    afterImg: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    profileImg: PropTypes.string,
    profileName: PropTypes.string,
};

export default UVTherapyCard;
