import PropTypes from 'prop-types';
import Banner from '@/organisms/Banner';
import Layout from 'components/Layout';
import UVDeviceSection from '@/organisms/UVDeviceSection';
import PsoriasisCareLevelSlider from '@/organisms/PsoriasisCareLevelSlider';
import UVTherapyCardSlider from '@/organisms/UVTherapyCardSlider';
import SectionHeading from '@/atoms/SectionHeading';
import PlanCard from '@/organisms/PlanCard';
import Faqs from '@/organisms/Faqs';
import ExploreSection from '@/organisms/ExploreSection';
import { redirectTo } from 'utils/router';
import WeekCarePlanOverview from '@/organisms/WeekCarePlanOverview';
import Text from '@/atoms/Text';
import Button from '@/atoms/Button';
import useRequestCallbackHook from '@/services/requestCallbackHook';
import FloatingIcon from '@/molecules/FloatingIcon';
import {
    DEFAULT_WHATSAPP_MESSAGE,
    DEFAULT_WHATSAPP_NUMBER,
} from 'constants/general-constants';
import {
    CARE_PLAN_HOME_PAGE_MESSAGE,
    DEVICE_ONLY_PLAN_HOME_PAGE_MESSAGE,
    LONG_LASTING_MESSAGE,
} from 'constants/message-constants';
import { CARE_PLAN_ID, DEVICE_ONLY_PLAN_ID } from 'constants/plan-constants';
import { getAuth, setAuth } from '@/services/identity.service';
import { usePlanStore } from 'store/plans';
import { useProfileStore } from 'store/profile';
import { SwiperSlide } from 'swiper/react';
import Slider from '@/molecules/Slider';
import Head from 'next/head';

const Home = ({
    handleBuyNow,
    faqList,
    handleToggleList,
    blogContent,
    handleRenew,
    uvDevicePlanDetails,
    carePlanDetails,
    categories,
    handleBuyUvDevicePlanNow,
    handleBuyCarePlan,
    heroBannerData,
    homePageTestimonialData,
    psoriasisCareLevelData,
    uvDeviceSectionData,
}) => {
    const { handleTicket } = useRequestCallbackHook();
    const setOpenCart = usePlanStore((state) => state.setOpenCart);
    const ordersCount = useProfileStore((state) => state.ordersCount);
    const setSelectedPlanId = usePlanStore((state) => state.setSelectedPlanId);

    const handleWhatsappChat = () => {
        const whatsappUrl = `https://wa.me/${DEFAULT_WHATSAPP_NUMBER}?text=${DEFAULT_WHATSAPP_MESSAGE}`;
        window.open(whatsappUrl, '_blank');
    };
    const handleBuyNowClick = (planId) => {
        const auth = getAuth();
        if (auth?.id_token && ordersCount > 0) {
            redirectTo('/purchase-history');
            return;
        }
        setSelectedPlanId(planId);
        setAuth({ planId });
        setOpenCart(() => true);
    };
    const getJSONLD = () => {
        return {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'Psoriasis Care and UV Therapy',
            description:
                'Explore our care plans and UV therapy devices designed for effective psoriasis management. Check out FAQs, testimonials, and more.',
            mainEntity: faqList.map((faq) => ({
                '@type': 'Question',
                name: faq.sections.map((section) => section.title).join(', '),
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: faq.sections
                        .map((section) => section.content)
                        .join(', '),
                },
            })),
        };
    };

    return (
        <>
            <Head>
                <title>
                    Psoriasis Relief with Doctor Recommended UV Therapy and
                    Wellness Plan | Dailybloom Psoriasis | Dr Reddys
                </title>
                <meta
                    name="description"
                    content="Discover effective solutions for managing psoriasis with our advanced UV device and personalized care plan. Get expert guidance and tailored support to improve your skin health and quality of life. Start your journey to clearer skin today!"
                />
                <meta
                    property="og:url"
                    content={`${process.env.NEXT_PUBLIC_WEBSITE_PAGE_URL}home`}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="Psoriasis Relief with Doctor Recommended UV Therapy and Wellness Plan | Dailybloom Psoriasis | Dr Reddy's"
                />
                <meta
                    property="og:description"
                    content="Discover effective solutions for managing psoriasis with our advanced UV device and personalized care plan. Get expert guidance and tailored support to improve your skin health and quality of life. Start your journey to clearer skin today!"
                />
                <script type="application/ld+json">
                    {JSON.stringify(getJSONLD())}
                </script>
            </Head>

            <Layout showlogoStrip={false}>
                <div className="bg-white flex flex-col gap-[90px] md:gap-[120px] lg:gap-[160px] pb-[90px] md:pb-[120px] lg:pb-40">
                    <div
                        id="what-is-dailyBloom"
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-once="true"
                    >
                        <Banner
                            handleBuyNow={handleBuyNow}
                            handleRequestCallback={() =>
                                handleTicket(LONG_LASTING_MESSAGE)
                            }
                            handleRenew={handleRenew}
                            heroBannerData={heroBannerData}
                        />
                    </div>

                    <div id="uv-device" data-aos="fade-up" data-aos-once="true">
                        <UVDeviceSection
                            handleBuyUvDevicePlanNow={handleBuyUvDevicePlanNow}
                            uvDeviceSectionData={uvDeviceSectionData}
                        />
                    </div>

                    <PsoriasisCareLevelSlider
                        handleBuyNow={handleBuyCarePlan}
                        psoriasisCareLevelData={psoriasisCareLevelData}
                    />

                    <div
                        id="results"
                        className="overflow-hidden"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <UVTherapyCardSlider
                            homePageTestimonialData={homePageTestimonialData}
                        />
                    </div>

                    <div id="plans-and-pricing" className="container">
                        <div data-aos="fade-up" data-aos-once="true">
                            <SectionHeading
                                align="center"
                                className="mb-6 lg:mb-10"
                                fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                            >
                                Plans and Pricing
                            </SectionHeading>
                        </div>
                        <div
                            className="hidden lg:flex flex-col-reverse lg:flex-row flex-wrap gap-y-10"
                            data-aos="fade-up"
                            data-aos-once="true"
                        >
                            <div
                                className="w-full lg:w-5/12"
                                data-aos="fade-right"
                                data-aos-once="true"
                                data-aos-delay="100"
                            >
                                <PlanCard
                                    cardBg="bg-primary-shade3"
                                    titleColor="text-grey-shade1"
                                    listColor="text-grey-shade2"
                                    planImg={uvDevicePlanDetails.planImg}
                                    mobilePlanImg={
                                        uvDevicePlanDetails.mobilePlanImg
                                    }
                                    planHeading={
                                        uvDevicePlanDetails.planHeading
                                    }
                                    planPrice={uvDevicePlanDetails.planPrice}
                                    planPriceText={
                                        uvDevicePlanDetails.planPriceText
                                    }
                                    featureList={
                                        uvDevicePlanDetails.featureList
                                    }
                                    handleBuyNow={() =>
                                        handleBuyNowClick(DEVICE_ONLY_PLAN_ID)
                                    }
                                    handleRequestCallback={() =>
                                        handleTicket(
                                            DEVICE_ONLY_PLAN_HOME_PAGE_MESSAGE
                                        )
                                    }
                                />
                            </div>
                            <div
                                className="w-full lg:w-7/12 lg:pl-5"
                                data-aos="fade-left"
                                data-aos-delay="400"
                                data-aos-once="true"
                            >
                                <PlanCard
                                    cardBg="bg-grey-shade1"
                                    showBgImage={true}
                                    listIcon="/images/icons/icon_check_w.svg"
                                    titleColor="text-white"
                                    listColor="text-white"
                                    planImg={carePlanDetails.planImg}
                                    mobilePlanImg={
                                        carePlanDetails.mobilePlanImg
                                    }
                                    planHeading={carePlanDetails.planHeading}
                                    planPrice={carePlanDetails.planPrice}
                                    planPriceText={
                                        carePlanDetails.planPriceText
                                    }
                                    featureList={carePlanDetails.featureList}
                                    btnAlignCenter={true}
                                    handleBuyNow={() =>
                                        handleBuyNowClick(CARE_PLAN_ID)
                                    }
                                    handleRequestCallback={() =>
                                        handleTicket(
                                            CARE_PLAN_HOME_PAGE_MESSAGE
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="block lg:hidden">
                            <Slider
                                showPagination={false}
                                slidesPerView={1}
                                spaceBetween={20}
                                loop={false}
                                style={{
                                    paddingBottom: '44px',
                                }}
                            >
                                <SwiperSlide>
                                    <div
                                        className="w-full"
                                        data-aos="fade-right"
                                        data-aos-once="true"
                                        data-aos-delay="100"
                                    >
                                        <PlanCard
                                            cardBg="bg-primary-shade3"
                                            titleColor="text-grey-shade1"
                                            listColor="text-grey-shade2"
                                            planImg={
                                                uvDevicePlanDetails.planImg
                                            }
                                            mobilePlanImg={
                                                uvDevicePlanDetails.mobilePlanImg
                                            }
                                            planHeading={
                                                uvDevicePlanDetails.planHeading
                                            }
                                            planPrice={
                                                uvDevicePlanDetails.planPrice
                                            }
                                            planPriceText={
                                                uvDevicePlanDetails.planPriceText
                                            }
                                            featureList={
                                                uvDevicePlanDetails.featureList
                                            }
                                            handleBuyNow={() =>
                                                handleBuyNowClick(
                                                    DEVICE_ONLY_PLAN_ID
                                                )
                                            }
                                            handleRequestCallback={() =>
                                                handleTicket(
                                                    DEVICE_ONLY_PLAN_HOME_PAGE_MESSAGE
                                                )
                                            }
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="w-full lg:w-7/12 lg:pl-5"
                                        data-aos="fade-left"
                                        data-aos-delay="400"
                                        data-aos-once="true"
                                    >
                                        <PlanCard
                                            cardBg="bg-grey-shade1"
                                            showBgImage={true}
                                            listIcon="/images/icons/icon_check_w.svg"
                                            titleColor="text-white"
                                            listColor="text-white"
                                            planImg={carePlanDetails.planImg}
                                            mobilePlanImg={
                                                carePlanDetails.mobilePlanImg
                                            }
                                            planHeading={
                                                carePlanDetails.planHeading
                                            }
                                            planPrice={
                                                carePlanDetails.planPrice
                                            }
                                            planPriceText={
                                                carePlanDetails.planPriceText
                                            }
                                            featureList={
                                                carePlanDetails.featureList
                                            }
                                            btnAlignCenter={true}
                                            handleBuyNow={() =>
                                                handleBuyNowClick(CARE_PLAN_ID)
                                            }
                                            handleRequestCallback={() =>
                                                handleTicket(
                                                    CARE_PLAN_HOME_PAGE_MESSAGE
                                                )
                                            }
                                        />
                                    </div>
                                </SwiperSlide>
                            </Slider>
                        </div>
                    </div>
                    <div id="plan-overview">
                        <div
                            className="container mx-auto mb-6 lg:mb-8"
                            data-aos="fade-up"
                            data-aos-once="true"
                        >
                            <SectionHeading
                                align="center"
                                fontSize="text-4xl/[38px] md:text-heading2 lg:text-5xl"
                            >
                                12-week Care Plan overview
                            </SectionHeading>
                            <Text
                                variant="body3"
                                fontWeight="font-medium"
                                className="text-center"
                            >
                                Here’s what you can expect in the next 12 weeks
                                of psoriasis management
                            </Text>
                        </div>
                        <div data-aos="fade-up" data-aos-once="true">
                            <WeekCarePlanOverview />
                        </div>
                        <div
                            className="max-w-[300px] w-full mx-auto mt-4"
                            data-aos="fade-up"
                            data-aos-once="true"
                        >
                            <Button
                                style="primary"
                                size="large"
                                label="Buy now"
                                onClick={handleBuyNow}
                                btnClass="w-full"
                            />
                        </div>
                    </div>
                    <ExploreSection
                        defalutCategory="Care Plan"
                        blogContent={blogContent}
                        categories={categories}
                    />
                    <Faqs list={faqList} handleToggleList={handleToggleList} />
                </div>
                <FloatingIcon onClick={handleWhatsappChat} />
            </Layout>
        </>
    );
};

Home.propTypes = {
    handleBuyNow: PropTypes.func,
    handleRequestCallback: PropTypes.func,
    handleRenew: PropTypes.func,
    UVTherapyList: PropTypes.array.isRequired,
    faqList: PropTypes.array.isRequired,
    handleToggleList: PropTypes.func.isRequired,
    uvDevicePlanDetails: PropTypes.shape({
        id: PropTypes.string,
        planImg: PropTypes.string,
        mobilePlanImg: PropTypes.string,
        planHeading: PropTypes.string,
        planPrice: PropTypes.string,
        planPriceText: PropTypes.string,
        featureList: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    carePlanDetails: PropTypes.shape({
        id: PropTypes.string,
        planImg: PropTypes.string,
        mobilePlanImg: PropTypes.string,
        planHeading: PropTypes.string,
        planPrice: PropTypes.string,
        planPriceText: PropTypes.string,
        featureList: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    blogContent: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            blogImg: PropTypes.string.isRequired,
            blogTitle: PropTypes.string.isRequired,
            profileImg: PropTypes.string.isRequired,
            profileName: PropTypes.string.isRequired,
            profileDesignation: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
            blogType: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
        })
    ).isRequired,
    isOpenRequestCallback: PropTypes.bool,
    register: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    handleMobileChange: PropTypes.func,
    handleAcknowledge: PropTypes.func,
    isCheckedAcknowledge: PropTypes.bool,
    handleCallSMS: PropTypes.func,
    isCheckedCallSMS: PropTypes.bool,
    handleCallBack: PropTypes.func,
    setOpenRequestCallback: PropTypes.func,
    isRequestCallback: PropTypes.bool,
    categories: PropTypes.array,
    handleBuyUvDevicePlanNow: PropTypes.func,
    handleBuyCarePlan: PropTypes.func,
    heroBannerData: PropTypes.object.isRequired,
    homePageTestimonialData: PropTypes.object.isRequired,
    psoriasisCareLevelData: PropTypes.array,
    uvDeviceSectionData: PropTypes.array,
};

export default Home;
