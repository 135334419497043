import Image from 'next/image';
import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';
import { useState } from 'react';
import Video from '@/molecules/CustomVideo';
import NextImage from 'next/image';
import Icon from '@/atoms/Icon';
import PropTypes from 'prop-types';

const UVDeviceVideoCard = ({ uvDeviceVideoCardData, videoLink }) => {
    const myLoader = ({ src }) => src;
    const [showVideo, setShowVideo] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false);

    const handleImageClick = () => {
        setShowVideo(true);
        setAutoPlay(true);
    };

    const handleCloseClick = () => {
        setShowVideo(false);
        setAutoPlay(false);
    };
    return (
        <div
            className={`flex flex-wrap items-center md:py-[90px] px-5 py-4 md:px-10 lg:p-10 min-h-[332px]`}
        >
            <div className="absolute w-full h-full inset-0 rounded-xl overflow-hidden">
                <NextImage
                    loader={myLoader}
                    src="/images/uv_device_bg.jpg"
                    layout="fill"
                    alt="bg image"
                />
            </div>
            <div
                className="w-full md:w-[40%] lg:w-[48%] md:pr-5 lg:pr-14 relative z-10 text-center md:text-left"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-once="true"
            >
                <Heading
                    type="h2"
                    fontColor="text-grey-shade1"
                    className="mb:mb-2"
                >
                    {uvDeviceVideoCardData.title}
                </Heading>
                <Text
                    fontSize="text-sm md:text-subHeading"
                    textColor="text-grey-shade1"
                >
                    {uvDeviceVideoCardData.subtitle}
                </Text>
            </div>
            <div
                className="w-full md:w-[60%] lg:w-[52%] relative z-10"
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-once="true"
            >
                <div
                    onClick={handleImageClick}
                    className="cursor-pointer  mx-auto mt-4 md:mt-0"
                >
                    <Image
                        loader={myLoader}
                        src={uvDeviceVideoCardData.videoThumbnail}
                        width={568}
                        height={320}
                        alt="device"
                        className="mx-auto h-[173px] md:h-[220px] lg:h-[320px] object-cover rounded-xl"
                    />
                </div>
                {showVideo && (
                    <div className="w-full left-0 top-0 h-full absolute rounded-xl overflow-hidden transition-all z-10">
                        <Video
                            className="w-full h-full"
                            videoSrc={videoLink}
                            autoPlay={autoPlay}
                        />

                        <span
                            onClick={handleCloseClick}
                            className="absolute top-4 right-4 bg-black text-white rounded-full w-8 h-8 flex items-center justify-center"
                        >
                            <Icon
                                src="/images/icons/icon_close.svg"
                                width={44}
                                height={44}
                                alt="closeModal"
                            />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

UVDeviceVideoCard.defaultProps = {};
UVDeviceVideoCard.propTypes = {
    uvDeviceVideoCardData: PropTypes.object,
    videoLink: PropTypes.string,
};

export default UVDeviceVideoCard;
