import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@/atoms/Heading';
import Overview from '@/molecules/Overview';
import Slider from '@/molecules/Slider';
import { SwiperSlide } from 'swiper/react';

const InfoGallery = ({
    list,
    heading,
    isSubHeading,
    subHeading,
    bgColor,
    textColor,
}) => {
    const renderOverview = (item) => (
        <Overview
            key={item.id}
            heading={item.heading}
            imageAlt={item.imageAlt}
            imageSrc={item.imageSrc}
            number={item.number}
            bgColor={bgColor}
            textColor={textColor}
        />
    );

    return (
        <div className="flex flex-col items-center">
            <div className="lg:px-10">
                <Heading
                    type="h3"
                    fontColor="text-grey-shade1"
                    fontSize="text-[23px]/[28px] md:text-h3"
                    className="max-w-[580px] lg:max-w-full md:text-center"
                    align="left"
                    fontFamily="font-body md:font-heading"
                    fontWeight="font-medium md:font-normal"
                >
                    {heading}
                </Heading>
                {isSubHeading && (
                    <Heading
                        type="h6"
                        fontColor="text-grey-shade3"
                        align="center"
                    >
                        {subHeading}
                    </Heading>
                )}
            </div>

            <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-10 container mt-8 md:mt-[120px] lg:mt-[72px] justify-center place-items-center">
                {list.map(renderOverview)}
            </div>

            <div className="md:hidden container mt-6 flex justify-center place-items-center">
                <Slider
                    slidesPerView={1}
                    style={{ paddingBottom: '44px' }}
                    loop={false}
                    showPagination={false}
                >
                    {list.map((item) => (
                        <SwiperSlide key={item.id}>
                            {renderOverview(item)}
                        </SwiperSlide>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

InfoGallery.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            heading: PropTypes.string.isRequired,
            imageAlt: PropTypes.string.isRequired,
            imageSrc: PropTypes.string.isRequired,
            number: PropTypes.number.isRequired,
        })
    ).isRequired,
    heading: PropTypes.string.isRequired,
    isSubHeading: PropTypes.bool,
    subHeading: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
};

InfoGallery.defaultProps = {
    isSubHeading: false,
    subHeading: '',
    bgColor: '',
    textColor: '',
};

export default InfoGallery;
